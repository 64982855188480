<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Cristian Valencia Agudelo                                     ###### -->
<!-- ###### @date: Enero 2025                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <section class="subHeader-content">
      <BlueHeader :itemsHeader="itemsHeader" />
  
      <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
  
  
    </section>
  </template>
  
  <script>
  import { Role } from "@/router/role.js";
  import BlueHeader from "../../../../components/BlueHeader.vue";
  
  export default {
    name: 'Cartas',
    components: { BlueHeader },
    data: () => ({
  
      itemsHeader: [
        {
          name: 'Cartas Agotado',
          link: { name: 'modules.settings.cartaAgotado.cartas' },
          rol: Role.Configuraciones_CartaAgotado_Admin,
        },
        {
          name: 'Carta',
          link: { name: 'modules.settings.cartaAgotado.new' },
          rol: Role.Configuraciones_CartaAgotado_Admin,
        }
      ]
    }),
  }
  </script>
  
  <style scoped>
  .subHeader-content {
    width: 96%;
  }
  </style>